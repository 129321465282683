import React, { Suspense, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import loadable from "@loadable/component";
import { jwtDecode } from "jwt-decode";
import { cloneDeep } from "lodash";

import { Loading } from "@/modules/shared/atoms";
import { routes, routesMap } from "@/app/consts";
import { permissionsMap } from "@/hooks/sheets/consts";

import type { TRoute, TToken } from "@/app/types";
import type { FC } from "react";
import type { TState } from "@/store/types";

const getUserType = () => {
  const token = localStorage.getItem("token");
  let userType = "user";

  if (token) {
    const decodedToken: TToken = jwtDecode(token);

    if (decodedToken.roles.indexOf("ROLE_MANAGER") !== -1) {
      userType = "manager";
    } else if (decodedToken.roles.indexOf("ROLE_SUPER_ADMIN") !== -1) {
      userType = "admin";
    }
  }

  return userType;
};

type NoMatchProps = {
  redirectRoute: string;
};

const NoMatch: FC<NoMatchProps> = (props) => {
  const { redirectRoute } = props;
  const userType = getUserType();

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.replace(
        userType === "manager"
          ? "/master-list/master"
          : userType === "admin"
          ? "/data-copy/data-copy-list"
          : redirectRoute
      );
    } else {
      history.replace("/auth/login");
    }
  }, []);

  return null;
};

const Routes: FC = () => {
  const userType = getUserType();

  const { userPermissions } = useSelector((state: TState) => state.user);

  const routesFilterFunction = (route: TRoute) => {
    let elemForPermission = userPermissions.find(
      (userPermission) => permissionsMap[route.key] === userPermission.code
    );

    if (elemForPermission && elemForPermission.parentId !== null) {
      const parentElem = userPermissions.find(
        (elem) => elem.id === elemForPermission?.parentId
      );

      if (parentElem && parentElem.status !== "open") {
        elemForPermission = { ...elemForPermission, status: parentElem.status };
      }
    }

    return (
      (route.permission === userType || !route.permission) &&
      elemForPermission?.status !== "hide" &&
      elemForPermission?.status !== "close"
    );
  };

  const filteredRoutes = useMemo(
    () => cloneDeep(routes).filter(routesFilterFunction),
    [routesFilterFunction, userPermissions]
  );

  const redirectRoute = useMemo(
    () =>
      filteredRoutes.find(
        (route) =>
          route.permission === userType &&
          !route.path.includes("auth") &&
          route.parentKey
      )?.path,
    [filteredRoutes]
  );

  return (
    <Switch>
      {filteredRoutes.map((route) => {
        if (route.parentKey) {
          const parentRoute = routesMap[route.parentKey];
          const ParentRouteComponent = loadable(
            () =>
              import(/* webpackPrefetch: true */ `../${parentRoute.component}`)
          );
          const RouteComponent = loadable(
            () => import(/* webpackPrefetch: true */ `../${route.component}`)
          );

          return (
            <Route key={route.key} exact path={route.path}>
              <Suspense fallback={<Loading />}>
                <ParentRouteComponent
                  customFiltersTypes={route.customFiltersTypes}
                  hideClientsList={route.hideClientsList}
                  hideHeaderControls={route.hideHeaderControls}
                  name={route.name}
                  title={route.title}
                  withSearch={route.withSearch}
                  withoutFilters={route.withoutFilters}
                  withoutLists={route.withoutLists}
                >
                  <RouteComponent />
                </ParentRouteComponent>
              </Suspense>
            </Route>
          );
        }
        const RouteComponent = loadable(
          () => import(/* webpackPrefetch: true */ `../${route.component}`)
        );

        return (
          <Route key={route.key} exact path={route.path}>
            <Suspense fallback={<Loading />}>
              <RouteComponent />
            </Suspense>
          </Route>
        );
      })}
      <Route path="*">
        <NoMatch redirectRoute={redirectRoute || "/"} />
      </Route>
    </Switch>
  );
};

export default Routes;
