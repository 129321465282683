import { useSelector } from "react-redux";
import { gql, useLazyQuery } from "@apollo/client";

import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";
import { camelToUpperSnake } from "@/utils/string-manipulations";

import type { TState } from "@/store/types";
import type { TExportFunction } from "@/modules/shared/types";

const useExportProducts = (
  exportProductsFunc: TExportFunction = "exportProducts"
) => {
  const { date, dynamicFilters, searchText } = useSelector(
    (state: TState) => state.filters
  );

  const { showFields, visibleFields } = useSelector(
    (state: TState) => state.tableFields
  );
  const fields = visibleFields.length
    ? visibleFields.map((field) => camelToUpperSnake(String(field)))
    : showFields?.map((field) => camelToUpperSnake(String(field.dataIndex)));

  const exportFunctions = {
    exportProducts: gql`
      query ExportProducts(
        $dates: DateRangeInput!
        $filters: [FilterInput!]
        $searchText: String
        $fields: [String!]
        $clientId: BigInt
      ) {
        exportProducts(
          dates: $dates
          exportType: EXPORT_PRODUCT_XLSX
          filters: $filters
          searchText: $searchText
          searchFields: ["title"]
          exportFields: $fields
          sort: { by: "DOOS", order: DESC }
          clientId: $clientId
        )
      }
    `,
    exportSerp: gql`
      query ExportSerp(
        $dates: DateRangeInput!
        $filters: [FilterInput!]
        $fields: [String!]!
      ) {
        exportSerp(
          dates: $dates
          filters: $filters
          exportFields: $fields
          sort: { by: "THE_DATE", order: DESC }
        )
      }
    `,
    exportReviews: gql`
      query ExportReviews($dates: DateRangeInput!, $filters: [FilterInput!]) {
        exportReviews(
          dates: $dates
          filters: $filters
          sort: { by: THE_DATE, order: DESC }
        )
      }
    `,

    exportQuestions: gql`
      query ExportReviews($dates: DateRangeInput!, $filters: [FilterInput!]) {
        exportReviews(
          dates: $dates
          filters: $filters
          sort: { by: THE_DATE, order: DESC }
        )
      }
    `,
  };

  const dates = {
    from: date.startDate?.format("YYYY-MM-DD") || "",
    to: date.endDate?.format("YYYY-MM-DD") || "",
  };

  const resultFilters = getFiltersFromDynamicFilters(dynamicFilters) || [];

  if (exportProductsFunc === "exportReviews") {
    resultFilters.push({ id: "reviewType", values: ["review"] });
  }

  if (exportProductsFunc === "exportQuestions") {
    resultFilters.push({ id: "reviewType", values: ["question"] });
  }

  const [getProductsExcelLink, { loading, error, data }] = useLazyQuery<{
    exportProducts?: string;
    exportSerp?: string;
    exportReviews?: string;
  }>(exportFunctions[exportProductsFunc], {
    variables: {
      dates,
      filters: resultFilters,
      searchText,
      fields,
    },
    fetchPolicy: "no-cache",
  });

  return {
    getProductsExcelLink,
    loading,
    data,
    error,
  };
};

export { useExportProducts };
