import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";

import type { FC } from "react";

import "./link-tab.scss";

type LinkTabProps = {
  title: string;
  path: string;
  locked?: boolean;
  icon?: React.ReactNode;
};

const LinkTab: FC<LinkTabProps> = (props) => {
  const { title, path, locked, icon } = props;

  const location = useLocation();

  const className = useMemo(
    () =>
      location.pathname === path ? "link-tab link-tab--active" : "link-tab",
    [location.pathname, path]
  );

  return (
    <div className={[className, locked ? "link-tab--locked" : ""].join(" ")}>
      {locked ? (
        <div>
          {" "}
          <LockOutlined /> {title}
        </div>
      ) : (
        <Link to={path}>
          {icon || null} {title}
        </Link>
      )}
    </div>
  );
};

export { LinkTab };
