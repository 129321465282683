import login from "./login/en.json";
import registration from "./registration/en.json";
import resetPassword from "./reset-password/en.json";
import availability from "./availability/en.json";
import pricesDiscounts from "./prices-discounts/en.json";
import content from "./content/en.json";
import productList from "./product-list/en.json";
import masterList from "./master-list/en.json";
import reviewsAndRating from "./reviews-and-rating/en.json";
import searchPosition from "./search-position/en.json";
import plannedReports from "./planned-reports/en.json";
import filterGroups from "./filter-groups/en.json";
import dataCopy from "./data-copy/en.json";
import products from "./products/en.json";
import profile from "./profile/en.json";
import serpProducts from "./serp-products/en.json";
import sales from "./sales/en.json";
import users from "./users/en.json";

export const pages = {
  pages: {
    ...login,
    ...registration,
    ...resetPassword,
    ...availability,
    ...pricesDiscounts,
    ...content,
    ...productList,
    ...masterList,
    ...reviewsAndRating,
    ...searchPosition,
    ...plannedReports,
    ...filterGroups,
    ...dataCopy,
    ...products,
    ...profile,
    ...serpProducts,
    ...sales,
    ...users,
  },
};
