import { gql } from "@apollo/client";

export default {
  getSerpPdpFilters: gql`
    query GetSerpStatsFilters(
      $dates: DateRangeInput!
      $ids: [String!]!
      $filters: [FilterInput!]
    ) {
      filters: getSerpStatsFilters(
        dates: $dates
        ids: $ids
        filters: $filters
      ) {
        id
        type
        suggest
        title
        values {
          id
          value
          description
        }
      }
    }
  `,
};
