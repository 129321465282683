import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "antd";
import { useApolloClient } from "@apollo/client";

import { setFilterConfigs, setUserData } from "@/store/user";
import LogOutIcon from "@/icons/shared/logout.svg";
import { routesMap } from "@/app/consts";
import { useAppDispatch } from "@/store";
import { resetDefaultSheetFilters } from "@/store/sheets";
import { clearFiltersStorage } from "@/modules/shared/utils/local-filters";

import type { FC } from "react";

import "./menu-item-profile.scss";

type MenuItemProfileProps = {
  title: string;
};

const MenuItemProfile: FC<MenuItemProfileProps> = ({ title }) => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const client = useApolloClient();

  const firstLetter = title?.charAt(0)?.toUpperCase();

  const logOut = async () => {
    if (localStorage.getItem("token")) {
      await client.clearStore();
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      dispatch(resetDefaultSheetFilters());
      dispatch(setUserData(null));
      dispatch(setFilterConfigs([]));
      history.push(routesMap.login.path);
      clearFiltersStorage();
    }
  };

  return (
    <Link className="menu-item-profile" to={routesMap.profileData.path}>
      <div className="menu-item-profile__icon">{firstLetter}</div>
      <span className="menu-item-profile__label">{title}</span>
      <Button icon={<LogOutIcon />} onClick={logOut} />
    </Link>
  );
};

export { MenuItemProfile };
