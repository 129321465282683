import { createSlice } from "@reduxjs/toolkit";

import { reducers } from "@/store/user/reducers";

import type {
  TUserState,
  TClientData,
  TUserData,
  TFilterConfigItem,
  TUserPermissionsItem,
} from "@/store/user/types";

const initialState: TUserState = {
  clientData: null,
  userData: null,
  userPermissions: [],
  filterConfigs: [],
  lastUpdate: null,
  lastSerpUpdate: null,
  lastReviewsUpdate: null,
};

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    ...reducers,
    reset: () => initialState,
  },
});

const userReducer = userSlice.reducer;

export type {
  TUserState,
  TClientData,
  TUserData,
  TFilterConfigItem,
  TUserPermissionsItem,
};

export { userReducer };

export const {
  setClientData,
  setUserData,
  setFilterConfigs,
  setLastUpdate,
  setLastSerpUpdate,
  setLastReviewsUpdate,
  setUserPermissions,
} = userSlice.actions;
