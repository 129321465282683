import { useCallback, useEffect, useMemo, useState } from "react";
import { clamp } from "lodash";

type TMovableGraphOptions<T> = {
  isHorizontal?: {
    height: number;
  };
  innerParamKey?: keyof T;
  resetDeps?: any[];
};

const useMovableGraph = <T>(
  initialData: T[] | undefined,
  options?: TMovableGraphOptions<T>
) => {
  const [firstVisibleItemRawIndex, setFirstVisibleItemRawIndex] = useState(0);

  let resetDepsString: string | undefined;

  if (options?.resetDeps?.length) {
    try {
      resetDepsString = JSON.stringify(options.resetDeps);
    } catch {}
  }

  // when the data of the graph changes, reset the firstVisibleItemRawIndex to 0
  useEffect(() => {
    setFirstVisibleItemRawIndex(0);
    // TODO should depend on whole initialData, not only its length
  }, [JSON.stringify(initialData), resetDepsString]);

  // TODO handle window resize
  const maxVisibleItems = useMemo(() => {
    // horizontal case
    if (options?.isHorizontal) {
      // TODO

      return 10;
    }

    // vertical case
    // TODO

    return 10;
    // TODO must depend on isHorizontal as whole but not get updated bcz of new object references
  }, [options?.isHorizontal?.height]);

  const maxInitialDataLength = useMemo(() => {
    if (options?.innerParamKey) {
      let maxLength = 0;

      initialData?.forEach((item) => {
        if ((item[options.innerParamKey!] as any[])?.length > maxLength) {
          maxLength = (item[options.innerParamKey!] as any[]).length;
        }
      });

      return maxLength || initialData?.length || 0;
    }

    return initialData?.length || 0;
  }, [JSON.stringify(initialData), options?.innerParamKey]);

  const data = useMemo<T[] | undefined>(() => {
    let dataToReturn = initialData;

    if (!options?.innerParamKey) {
      dataToReturn = dataToReturn?.slice(
        firstVisibleItemRawIndex,
        firstVisibleItemRawIndex + maxVisibleItems
      );
    } else {
      dataToReturn = dataToReturn?.map((item) => ({
        ...item,
        [options.innerParamKey!]: (item[options.innerParamKey!] as any[]).slice(
          firstVisibleItemRawIndex,
          firstVisibleItemRawIndex + maxVisibleItems
        ),
      }));
    }

    // NOTE: no innerParamKey support for horizontal
    if (options?.isHorizontal) {
      dataToReturn = dataToReturn?.reverse();
    }

    return dataToReturn;
  }, [
    JSON.stringify(initialData),
    maxVisibleItems,
    firstVisibleItemRawIndex,
    // TODO must depend on isHorizontal as whole but not get updated bcz of new object references
    options?.isHorizontal?.height,
  ]);

  const possibleMoveGraph = useMemo(
    () => ({
      backward: firstVisibleItemRawIndex > 0,
      forward: maxInitialDataLength
        ? firstVisibleItemRawIndex + maxVisibleItems < maxInitialDataLength
        : false,
    }),
    [firstVisibleItemRawIndex, maxVisibleItems, maxInitialDataLength]
  );

  const moveGraphBy = useCallback(
    (amount: number) => {
      if (maxInitialDataLength) {
        setFirstVisibleItemRawIndex((prevState) =>
          clamp(prevState + amount, 0, maxInitialDataLength - maxVisibleItems)
        );
      }
    },
    [maxInitialDataLength, maxVisibleItems]
  );

  const onMoveGraphBy = useCallback(
    (amount: number) => () => moveGraphBy(amount),
    [moveGraphBy]
  );

  return {
    data,
    moveGraphBy,
    onMoveGraphBy,
    possibleMoveGraph,
  };
};

export { useMovableGraph };
