import React from "react";

import AvailabilityIcon from "@/icons/menu/availability-icon.svg";
import ServicesIcon from "@/icons/menu/services-icon.svg";

import type { TMenuRoute } from "@/app/types/route";
import type { TRoute } from "@/app/types";

export const routesMap: Record<string, TRoute> = {
  login: {
    key: "login",
    name: "login",
    title: "modules.organisms.shared.login",
    path: "/auth/login",
    component: "pages/auth/login",
    layout: "layouts/auth",
  },
  registration: {
    key: "registration",
    name: "registration",
    title: "modules.organisms.shared.registration",
    path: "/auth/registration",
    component: "pages/auth/registration",
    layout: "layouts/auth",
  },
  resetPassword: {
    key: "reset-password",
    name: "reset-password",
    title: "modules.organisms.shared.reset-password",
    path: "/auth/reset-password",
    component: "pages/auth/reset-password",
    layout: "layouts/auth",
  },
  resetPasswordKey: {
    key: "reset-password",
    name: "reset-password",
    title: "modules.organisms.shared.reset-password",
    path: "/auth/reset-password/:token",
    component: "pages/auth/reset-password-token",
    layout: "layouts/auth",
  },

  profile: {
    name: "profile",
    key: "profile",
    title: "modules.organisms.shared.profile",
    path: "/profile",
    component: "pages/profile/profile",
    layout: "layouts/profile",
    permission: "user",
  },
  profileData: {
    name: "profile-data",
    key: "profile-data",
    title: "modules.organisms.shared.profile-data",
    path: "/profile/data",
    component: "pages/profile/profile/children/profile-data",
    parentKey: "profile",
    permission: "user",
  },
  content: {
    name: "content",
    key: "content",
    title: "modules.organisms.shared.content",
    path: "/content",
    component: "pages/default/content",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
  },
  contentGeneralInformation: {
    name: "content-general-information",
    key: "content-general-information",
    title: "modules.organisms.shared.general-information",
    path: "/content/general-information",
    component: "pages/default/content/children/general-information",
    parentKey: "content",
    permission: "user",
  },
  contentMyLists: {
    name: "content-my-lists",
    key: "content-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/content/my-lists",
    component: "pages/default/content/children/my-lists",
    parentKey: "content",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },
  contentImages: {
    name: "content-images",
    key: "content-images",
    title: "modules.organisms.shared.images",
    path: "/content/images",
    component: "pages/default/content/children/images",
    parentKey: "content",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },

  availability: {
    name: "availability",
    key: "availability",
    title: "modules.organisms.shared.availability",
    path: "/availability",
    component: "pages/default/availability",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
    hideHeaderControls: true,
  },
  availabilityGeneralInformation: {
    name: "general-information",
    key: "availability-general-information",
    title: "modules.organisms.shared.general-information",
    path: "/availability/general-information",
    component: "pages/default/availability/children/general-information",
    parentKey: "availability",
    permission: "user",
    hideHeaderControls: true,
  },
  availabilityMyLists: {
    name: "my-lists",
    key: "availability-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/availability/my-lists",
    component: "pages/default/availability/children/my-lists",
    parentKey: "availability",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },
  availabilityMatrix: {
    name: "matrix",
    key: "availability-matrix",
    title: "modules.organisms.shared.matrix",
    path: "/availability/matrix",
    component: "pages/default/availability/children/matrix",
    parentKey: "availability",
    permission: "user",
    customFiltersTypes: {
      retailer: "tags",
    },
  },

  "search-position": {
    name: "search-position",
    key: "search-position",
    title: "modules.organisms.shared.search-position",
    path: "/search-position",
    component: "pages/default/search-position",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
  },
  searchPositionGeneralInformation: {
    name: "general-information",
    key: "search-position-general-information",
    title: "modules.organisms.shared.general-information",
    path: "/search-position/general-information",
    component: "pages/default/search-position/children/general-information",
    parentKey: "search-position",
    permission: "user",
  },
  searchPositionMyLists: {
    name: "my-lists",
    key: "search-position-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/search-position/my-lists",
    component: "pages/default/search-position/children/my-lists",
    parentKey: "search-position",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },

  "prices-discounts": {
    name: "prices-discounts",
    key: "prices-discounts",
    title: "modules.organisms.shared.prices-discounts",
    path: "/prices-discounts",
    component: "pages/default/prices-discounts",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
    hideHeaderControls: true,
  },
  pricesDiscountsGeneralInformation: {
    name: "prices-discounts-general-information",
    key: "prices-discounts-general-information",
    title: "modules.organisms.shared.general-information",
    path: "/prices-discounts/general-information",
    component: "pages/default/prices-discounts/children/general-information",
    parentKey: "prices-discounts",
    permission: "user",
    hideHeaderControls: true,
  },
  pricesDiscountsMyLists: {
    name: "prices-discounts-my-lists",
    key: "prices-discounts-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/prices-discounts/my-lists",
    component: "pages/default/prices-discounts/children/my-lists",
    parentKey: "prices-discounts",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },

  pricesDiscountsMatrix: {
    name: "prices-discounts-matrix",
    key: "prices-discounts-matrix",
    title: "modules.organisms.shared.matrix",
    path: "/prices-discounts/matrix",
    component: "pages/default/prices-discounts/children/matrix",
    parentKey: "prices-discounts",
    permission: "user",
    customFiltersTypes: {
      retailer: "tags",
    },
  },

  sales: {
    name: "sales",
    key: "sales",
    title: "modules.organisms.shared.sales",
    path: "/sales",
    component: "pages/default/sales",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
  },
  salesGeneralInformation: {
    name: "sales-general-information",
    key: "sales-general-information",
    title: "modules.organisms.shared.general-information",
    path: "/sales/general-information",
    component: "pages/default/sales/children/general-information",
    parentKey: "sales",
    permission: "user",
  },
  salesMyLists: {
    name: "sales-my-lists",
    key: "sales-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/sales/my-lists",
    component: "pages/default/sales/children/my-lists",
    parentKey: "sales",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },

  "product-list": {
    name: "product-list",
    key: "product-list",
    title: "modules.organisms.shared.product-list",
    path: "/product-list",
    component: "pages/default/product-list",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
    withoutLists: true,
    hideHeaderControls: true,
  },
  productListMapping: {
    name: "product-list-mapping",
    key: "product-list-mapping",
    title: "modules.organisms.shared.mapping",
    path: "/product-list/mapping",
    component: "pages/default/product-list/children/mapping",
    parentKey: "product-list",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
    withoutLists: true,
    hideHeaderControls: true,
  },
  productListProducts: {
    name: "product-list-products",
    key: "product-list-products",
    title: "modules.organisms.shared.products-list",
    path: "/product-list/products",
    component: "pages/default/product-list/children/products",
    parentKey: "product-list",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
    withoutLists: true,
    hideHeaderControls: true,
  },
  productListCustomFields: {
    name: "product-list-custom-fields",
    key: "product-list-custom-fields",
    title: "modules.organisms.shared.custom-fields",
    path: "/product-list/custom-fields",
    component: "pages/default/product-list/children/custom-fields",
    parentKey: "product-list",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
    withoutLists: true,
    hideHeaderControls: true,
  },

  "master-list": {
    name: "master-list",
    key: "master-list",
    title: "modules.organisms.shared.product-list",
    path: "/master-list",
    component: "pages/admin/master-list",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "manager",
    withoutLists: true,
    hideHeaderControls: true,
  },

  masterListMaster: {
    name: "product-list-master",
    key: "product-list-master",
    title: "modules.organisms.shared.master-list",
    path: "/master-list/master",
    component: "pages/admin/master-list/children/master",
    parentKey: "master-list",
    permission: "manager",
    withoutFilters: true,
    withSearch: true,
    withoutLists: true,
    hideHeaderControls: true,
  },
  masterListProducts: {
    name: "product-list-master-products",
    key: "product-list-master-products",
    title: "modules.organisms.shared.products",
    path: "/master-list/master-products",
    component: "pages/admin/master-list/children/master-products",
    parentKey: "master-list",
    permission: "manager",
    withoutFilters: true,
    withSearch: true,
    withoutLists: true,
    hideHeaderControls: true,
  },
  products: {
    name: "products",
    key: "products",
    title: "modules.organisms.shared.products",
    path: "/products",
    component: "pages/admin/products",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "manager",
    hideHeaderControls: true,
  },
  productsMyLists: {
    name: "my-lists",
    key: "products-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/products/my-lists",
    component: "pages/admin/products/children/my-lists",
    parentKey: "products",
    permission: "manager",
    hideHeaderControls: false,
    withoutFilters: true,
    withSearch: true,
    withoutLists: true,
  },
  productsPdpLists: {
    name: "pdp-lists",
    key: "products-pdp-lists",
    title: "modules.organisms.shared.pdp-list",
    path: "/products/stats-locations",
    component: "pages/admin/products/children/pdp-lists",
    parentKey: "products",
    permission: "manager",
    hideHeaderControls: true,
    withoutFilters: true,
    withSearch: false,
    withoutLists: true,
    hideClientsList: true,
  },
  productsPdpStatisticsLists: {
    name: "pdp-statistics-lists",
    key: "pdp-statistics-lists",
    title: "modules.organisms.shared.pdp-statistics-lists",
    path: "/products/stats",
    component: "pages/admin/products/children/pdp-statistics-lists",
    parentKey: "products",
    permission: "manager",
    hideHeaderControls: true,
    withoutFilters: true,
    withSearch: false,
    withoutLists: true,
    hideClientsList: true,
  },
  productsPdpIndexingStats: {
    name: "pdp-indexing-stats",
    key: "products-pdp-indexing-stats",
    title: "modules.organisms.shared.pdp-indexing-stats",
    path: "/products/indexing-stats",
    component: "pages/admin/products/children/pdp-indexing-stats",
    parentKey: "products",
    permission: "manager",
    hideHeaderControls: true,
    withoutFilters: true,
    withSearch: false,
    withoutLists: true,
    hideClientsList: true,
  },
  productsPdpCollectionMatrix: {
    name: "pdp-collection",
    key: "pdp-collection",
    title: "modules.organisms.shared.pdp-collection",
    path: "/products/matrix",
    component: "pages/admin/products/children/pdp-collection",
    parentKey: "products",
    permission: "manager",
    hideHeaderControls: true,
    withoutFilters: false,
    withSearch: false,
  },
  "serp-products": {
    name: "serp-products",
    key: "serp-products",
    title: "modules.organisms.shared.place",
    path: "/serp-products",
    component: "pages/admin/serp-products",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "manager",
    hideHeaderControls: true,
  },
  serpProductsMyLists: {
    name: "my-lists",
    key: "serp-products-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/serp-products/my-lists",
    component: "pages/admin/serp-products/children/my-lists",
    parentKey: "serp-products",
    permission: "manager",
    withoutFilters: true,
    hideHeaderControls: true,
    withSearch: true,
  },
  serpProductsPdpLists: {
    name: "serp-pdp-lists",
    key: "serp-pdp-lists",
    title: "modules.organisms.shared.serp-pdp-list",
    path: "/serp-products/stats-locations",
    component: "pages/admin/serp-products/children/serp-pdp-lists",
    parentKey: "serp-products",
    permission: "manager",
    hideHeaderControls: true,
    withoutFilters: true,
    withSearch: false,
    withoutLists: true,
    hideClientsList: true,
  },
  serpProductsPdpStatisticsLists: {
    name: "serp-pdp-statistics-lists",
    key: "serp-pdp-statistics-lists",
    title: "modules.organisms.shared.serp-pdp-statistics-list",
    path: "/serp-products/stats",
    component: "pages/admin/serp-products/children/serp-pdp-statistics-lists",
    parentKey: "serp-products",
    permission: "manager",
    hideHeaderControls: true,
    withoutFilters: true,
    withSearch: false,
    withoutLists: true,
    hideClientsList: true,
  },
  serpIndexingStats: {
    name: "serp-indexing-stats",
    key: "serp-indexing-stats",
    title: "modules.organisms.shared.serp-indexing-stats",
    path: "/serp-products/indexing-stats",
    component: "pages/admin/serp-products/children/serp-indexing-stats",
    parentKey: "serp-products",
    permission: "manager",
    hideHeaderControls: true,
    withoutFilters: true,
    withSearch: false,
    withoutLists: true,
    hideClientsList: true,
  },
  serpProductsSerpCollectionMatrix: {
    name: "serp-collection",
    key: "serp-collection",
    title: "modules.organisms.shared.serp-collection",
    path: "/serp-products/matrix",
    component: "pages/admin/serp-products/children/serp-collection",
    parentKey: "serp-products",
    permission: "manager",
    hideHeaderControls: true,
    withoutFilters: false,
    withSearch: false,
  },
  "reviews-and-rating": {
    name: "reviews-and-rating",
    key: "reviews-and-rating",
    title: "modules.organisms.shared.reviews-and-rating",
    path: "/reviews-and-rating",
    component: "pages/default/reviews-and-rating",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
    hideHeaderControls: true,
  },
  reviewsAndRatingGeneralInformation: {
    name: "reviews-and-rating-general-information",
    key: "reviews-and-rating-general-information",
    title: "modules.organisms.shared.general-information",
    path: "/reviews-and-rating/general-information",
    component: "pages/default/reviews-and-rating/children/general-information",
    parentKey: "reviews-and-rating",
    permission: "user",
    hideHeaderControls: true,
  },
  reviewsAndRatingMyLists: {
    name: "reviews-and-rating-my-lists",
    key: "reviews-and-rating-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/reviews-and-rating/my-lists",
    component: "pages/default/reviews-and-rating/children/my-lists",
    parentKey: "reviews-and-rating",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },
  reviewsTable: {
    name: "reviews-table",
    key: "reviews-table",
    title: "modules.organisms.shared.reviews",
    path: "/reviews-and-rating/reviews",
    component: "pages/default/reviews-and-rating/children/reviews",
    parentKey: "reviews-and-rating",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },
  questionsTable: {
    name: "questions-table",
    key: "questions-table",
    title: "modules.organisms.shared.questions",
    path: "/reviews-and-rating/questions",
    component: "pages/default/reviews-and-rating/children/questions",
    parentKey: "reviews-and-rating",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },

  "planned-reports": {
    name: "planned-reports",
    key: "planned-reports",
    title: "modules.organisms.shared.planned-reports",
    path: "/planned-reports",
    component: "pages/default/planned-reports",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
    withoutLists: true,
    hideHeaderControls: true,
  },
  plannedReportsReady: {
    name: "planned-reports-ready",
    key: "planned-reports-ready",
    title: "modules.organisms.shared.ready",
    path: "/planned-reports/ready",
    component: "pages/default/planned-reports/children/ready",
    parentKey: "planned-reports",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
    hideHeaderControls: true,
  },
  plannedReportsReadyDownload: {
    permission: "user",
    key: "planned-reports-ready-download",
    name: "planned-reports-ready-download",
    title: "modules.organisms.shared.ready",
    path: "/planned-reports/ready/:reportId/download",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    component: "pages/default/planned-reports/children/download",
  },
  plannedReportsPlanned: {
    name: "planned-reports-planned",
    key: "planned-reports-planned",
    title: "modules.organisms.shared.planned",
    path: "/planned-reports/planned",
    component: "pages/default/planned-reports/children/planned",
    parentKey: "planned-reports",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
    hideHeaderControls: true,
  },
  "filter-groups": {
    name: "filter-groups",
    key: "filter-groups",
    title: "modules.organisms.shared.filter-groups",
    path: "/filter-groups",
    component: "pages/default/filter-groups",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
    withoutLists: true,
    hideHeaderControls: true,
  },
  filterGroupsBrand: {
    name: "filter-groups-brand",
    key: "filter-groups-brand",
    title: "modules.organisms.shared.filter-groups-brand",
    path: "/filter-groups/brand",
    component: "pages/default/filter-groups/children/brand",
    parentKey: "filter-groups",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
    hideHeaderControls: true,
  },
  filterGroupsSeller: {
    name: "filter-groups-seller",
    key: "filter-groups-seller",
    title: "modules.organisms.shared.filter-groups-seller",
    path: "/filter-groups/seller",
    component: "pages/default/filter-groups/children/seller",
    parentKey: "filter-groups",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
    hideHeaderControls: true,
  },
  filterGroupsCategory: {
    name: "filter-groups-category",
    key: "filter-groups-category",
    title: "modules.organisms.shared.filter-groups-category",
    path: "/filter-groups/category",
    component: "pages/default/filter-groups/children/category",
    parentKey: "filter-groups",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
    hideHeaderControls: true,
  },
  "bi-report": {
    name: "bi-report",
    key: "bi-report",
    title: "modules.organisms.shared.bi-report",
    path: "/bi-report",
    component: "pages/default/bi-report",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
  },
  BIReportsReport: {
    name: "bi-report-report",
    key: "bi-report-report",
    title: "modules.organisms.shared.report",
    path: "/bi-report/report",
    component: "pages/default/bi-report/children/report",
    parentKey: "bi-report",
    permission: "user",
    withoutLists: true,
    withoutFilters: true,
    withSearch: false,
    hideHeaderControls: true,
  },
  table: {
    name: "table",
    key: "table",
    title: "modules.organisms.shared.my-lists",
    path: "/table",
    component: "pages/default/table",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "user",
  },
  tableMyLists: {
    name: "table-my-lists",
    key: "table-my-lists",
    title: "modules.organisms.shared.my-lists",
    path: "/table/my-lists",
    component: "pages/default/table/children/my-lists",
    parentKey: "table",
    permission: "user",
    withoutFilters: true,
    withSearch: true,
  },
  "data-copy": {
    name: "data-copy",
    key: "data-copy",
    title: "modules.organisms.shared.data-copy",
    path: "/data-copy",
    component: "pages/admin/data-copy",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "admin",
    hideHeaderControls: true,
  },
  dataCopyList: {
    name: "data-copy-list",
    key: "data-copy-list",
    title: "modules.organisms.shared.data-copy-list",
    path: "/data-copy/data-copy-list",
    component: "pages/admin/data-copy/children/data-copy-list",
    parentKey: "data-copy",
    permission: "admin",
    withoutFilters: true,
    withSearch: true,
    withoutLists: true,
    hideHeaderControls: true,
  },
  users: {
    name: "users",
    key: "users",
    title: "modules.organisms.shared.users",
    path: "/users",
    component: "pages/admin/users",
    layout: "layouts/default",
    icon: <AvailabilityIcon />,
    permission: "admin",
    withoutLists: true,
    hideHeaderControls: true,
  },
  clientsList: {
    name: "clients",
    key: "clients",
    title: "modules.organisms.shared.clients",
    path: "/users/clients",
    component: "pages/admin/users/children/clients",
    parentKey: "users",
    permission: "admin",
    withoutFilters: true,
    withSearch: true,
    hideHeaderControls: true,
  },
  usersList: {
    name: "users",
    key: "users",
    title: "modules.organisms.shared.users",
    path: "/users/users",
    component: "pages/admin/users/children/users",
    parentKey: "users",
    permission: "admin",
    withoutFilters: true,
    withSearch: true,
    hideHeaderControls: true,
  },
  userStats: {
    name: "users-stats",
    key: "users-stats",
    title: "modules.organisms.shared.users-stats",
    path: "/users/users-stats",
    component: "pages/admin/users/children/users-stats",
    parentKey: "users",
    permission: "admin",
    withoutFilters: true,
    withSearch: true,
    hideHeaderControls: true,
  },
};

export const menuRoutesMap: Record<string, TMenuRoute> = {
  reports: {
    key: "reports",
    name: "reports",
    title: "modules.organisms.shared.reports",
    label: "modules.organisms.shared.reports",
    icon: <AvailabilityIcon />,
    popupClassName: "not-to-display",
    permission: "user",
    children: [
      {
        key: "content",
        name: "content",
        title: "modules.organisms.shared.content",
        label: "modules.organisms.shared.content",
        path: "/content/general-information",
        permission: "user",
      },
      {
        key: "reviews-and-rating",
        name: "reviews-and-rating",
        title: "modules.organisms.shared.reviews-and-rating",
        label: "modules.organisms.shared.reviews-and-rating",
        path: "/reviews-and-rating/general-information",
        permission: "user",
      },
      {
        key: "availability",
        name: "availability",
        title: "modules.organisms.shared.availability",
        label: "modules.organisms.shared.availability",
        path: "/availability/general-information",
        permission: "user",
      },
      {
        key: "search-position",
        name: "search-position",
        title: "modules.organisms.shared.search-position",
        label: "modules.organisms.shared.search-position",
        path: "/search-position/general-information",
        permission: "user",
      },
      {
        key: "prices-discounts",
        name: "prices-discounts",
        title: "modules.organisms.shared.prices-discounts",
        label: "modules.organisms.shared.prices-discounts",
        path: "/prices-discounts/general-information",
        permission: "user",
      },
      {
        key: "sales",
        name: "sales",
        title: "modules.organisms.shared.sales",
        label: "modules.organisms.shared.sales",
        path: "/sales/general-information",
        permission: "user",
      },
      {
        key: "buy-box",
        name: "buy-box",
        title: "modules.organisms.shared.buy-box",
        label: "modules.organisms.shared.buy-box",
        path: "/",
        permission: "user",
        disabled: true,
      },
      {
        key: "bi-report",
        name: "bi-report",
        title: "modules.organisms.shared.bi-report",
        label: "modules.organisms.shared.bi-report",
        path: "/bi-report/report",
        permission: "user",
      },
      // {
      //   key: "table",
      //   name: "table",
      //   title: "modules.organisms.shared.my-lists",
      //   label: "modules.organisms.shared.my-lists",
      //   path: "/table/my-lists",
      //   permission: "user",
      // },
    ],
  },
  services: {
    key: "services",
    name: "services",
    title: "modules.organisms.shared.services",
    label: "modules.organisms.shared.services",
    icon: <ServicesIcon />,
    popupClassName: "not-to-display",
    children: [
      {
        key: "product-list",
        name: "product-list",
        title: "modules.organisms.shared.product-list",
        label: "modules.organisms.shared.product-list",
        path: "/product-list/mapping",
        permission: "user",
      },
      {
        key: "master-list",
        name: "master-list",
        title: "modules.organisms.shared.master-list",
        label: "modules.organisms.shared.master-list",
        path: "/master-list/master",
        permission: "manager",
      },
      {
        key: "products",
        name: "products",
        title: "modules.organisms.shared.products",
        label: "modules.organisms.shared.products",
        path: "/products/my-lists",
        permission: "manager",
      },
      {
        key: "serp-products",
        name: "serp-products",
        title: "modules.organisms.shared.place",
        label: "modules.organisms.shared.place",
        path: "/serp-products/my-lists",
        permission: "manager",
      },
      {
        key: "planned-reports",
        name: "planned-reports",
        title: "modules.organisms.shared.planned-reports",
        label: "modules.organisms.shared.planned-reports",
        path: "/planned-reports/ready",
        permission: "user",
      },
      // {
      //   key: "saved-filters",
      //   name: "saved-filters",
      //   title: "modules.organisms.shared.saved-filters",
      //   label: "modules.organisms.shared.saved-filters",
      //   path: "/saved-filters",
      //   permission: "user",
      //   disabled: true,
      // },
      {
        key: "filter-groups",
        name: "filter-groups",
        title: "modules.organisms.shared.filter-groups",
        label: "modules.organisms.shared.filter-groups",
        path: "/filter-groups/brand",
        permission: "user",
      },
      {
        key: "",
        name: "data-copy",
        title: "modules.organisms.shared.data-copy",
        label: "modules.organisms.shared.data-copy",
        path: "/data-copy/data-copy-list",
        permission: "admin",
      },
      {
        key: "users",
        name: "users",
        title: "modules.organisms.shared.users",
        label: "modules.organisms.shared.users",
        path: "/users/clients",
        permission: "admin",
      },
    ],
  },
};

export const menuRoutes: TMenuRoute[] = Object.values(menuRoutesMap);

export const routes: TRoute[] = Object.values(routesMap);

export const getParentRoute = (path: string) =>
  routes.find((route) => route.path === path)?.parentKey
    ? routesMap[routes.find((route) => route.path === path)!.parentKey!]
    : undefined;

export const getChildRoutes = (path: string) =>
  routes.find((route) => route.path === path) &&
  routes.filter(
    (item) =>
      item.parentKey === routes.find((route) => route.path === path)!.key
  )?.length
    ? routes.filter(
        (item) =>
          item.parentKey === routes.find((route) => route.path === path)!.key
      )
    : undefined;
