import { useSelector } from "react-redux";
import { gql, useQuery } from "@apollo/client";

import { getFiltersFromDynamicFilters } from "@/modules/shared/utils";

import type { TProduct } from "@/modules/shared/types";
import type { TState } from "@/store/types";

export type TResponse<T extends Partial<TProduct>> = {
  getProducts: {
    products: T[];
    pagination: {
      limit: number;
      page: number;
      total: number;
      pages: number;
    };
  };
};

type TPagination = {
  limit: number;
  page: number;
};

type TSort = {
  by: string;
  order: string;
};

const useSerpSummaryStats = <T extends Partial<TProduct> = TProduct>(
  pagination: TPagination,
  sort: TSort,
  productParams: (keyof TProduct)[],
  skip: boolean,
  clientId?: number
) => {
  const { date, dynamicFilters } = useSelector(
    (state: TState) => state.filters
  );

  const productParamsUpd = productParams.map((param) =>
    param === "bonusInfo" ? "bonusInfo { type, title, totalAmount}" : param
  );

  const GET_PDP_STATS = gql`
      query GetSerpSummaryStats(
          $dates: DateRangeInput!
          $filters: [FilterInput!]
          $pagination: PaginationInput!
          $sort: SerpSummaryStatsSortInput!
      ) {
          getProducts: getSerpSummaryStats(
              dates: $dates
              filters: $filters
              pagination: $pagination
              sort: $sort
          ) {
              products: serpStats {
                ${productParamsUpd.join("\n")}
              }
              pagination {
                  limit
                  page
                  total
                  pages
              }
          }
      }
  `;

  const dates = {
    from: date.startDate?.format("YYYY-MM-DD") || "",
    to: date.endDate?.format("YYYY-MM-DD") || "",
  };

  const { loading, error, data } = useQuery<
    TResponse<T & Pick<TProduct, "spid">>
  >(GET_PDP_STATS, {
    variables: {
      dates,
      filters: getFiltersFromDynamicFilters(dynamicFilters),
      pagination,
      sort,
      clientId,
    },
    skip,
    fetchPolicy: "no-cache",
  });

  return { loading, error, data, date, dynamicFilters };
};

export { useSerpSummaryStats };
