import React from "react";
import { MenuOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import EyeClosed from "@/icons/modules/shared/eye-closed.svg";
import EyeOpened from "@/icons/modules/shared/eye-opened.svg";

type SortableItemProps = {
  id: string;
  label: string;
  selected: boolean;
  handleSelectedKey: () => void;
};

const SortableItem = ({
  id,
  label,
  selected,
  handleSelectedKey,
}: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: "flex",
    alignItems: "center",
    padding: "12px",
    opacify: selected ? 1 : 0.5,
    background: selected ? "transparent" : "#f1f4f8",
    borderRadius: "12px",
    cursor: "grab",
  };

  return (
    <li ref={setNodeRef} style={styles} {...attributes}>
      <div
        style={{ display: "flex", alignItems: "center", flex: 1 }}
        {...listeners}
      >
        <MenuOutlined />
        <p style={{ color: selected ? undefined : "#8D9CA8", flex: 1 }}>
          {label}
        </p>
      </div>
      <div
        className="eye"
        onClick={handleSelectedKey}
        style={{
          cursor: "pointer",
        }}
      >
        {selected ? <EyeOpened /> : <EyeClosed />}
      </div>
    </li>
  );
};

export { SortableItem };
