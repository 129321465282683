import { gql } from "@apollo/client";

export default gql`
  query GetPdpStatsFilter(
    $dates: DateRangeInput!
    $id: String!
    $filters: [FilterInput!]
  ) {
    getPdpStatsFilter(dates: $dates, id: $id, filters: $filters) {
      id
      type
      suggest
      title
      values {
        id
        value
        description
      }
    }
  }
`;
